<template>
  <div
    class="c-accordion"
    :class="{ open: isContentVisible }"
  >
    <BaseButton2
      class="c-accordion__button"
      :disabled
      @click="() => toggleContent()"
    >
      <slot name="title">
        <div class="c-accordion__title">{{ title }}</div>
      </slot>

      <slot
        v-if="!hideButtonIcon"
        v-bind="{ show: isContentVisible }"
        name="button"
      >
        <BaseIcon2
          id="icon-cap"
          class="c-accordion__icon"
          height="16"
          width="16"
        />
      </slot>
    </BaseButton2>

    <div
      v-show="isContentVisible"
      class="c-accordion__content"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  disabled?: boolean;
  hideButtonIcon?: boolean;
  open?: boolean;
  openInit?: boolean;
  title?: string;
}

const props = defineProps<Props>();

const emits = defineEmits<{
  close: [void];
  open: [void];
}>();

defineSlots<{
  button: [];
  content: [];
  title: [];
}>();

const { open, openInit } = toRefs(props);
const isContentVisible = ref(open.value || openInit.value);

const toggleContent = () => {
  if (isContentVisible.value) {
    isContentVisible.value = false;
    emits("close");
  } else {
    isContentVisible.value = true;
    emits("open");
  }
};

watch(
  () => open.value,
  () => (isContentVisible.value = open.value),
);
</script>

<style scoped>
.c-accordion {
  display: flex;
  flex-direction: column;
}

.c-accordion__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition-property: transform, color, background-color, fill, stroke;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}
</style>
